import React, { useContext, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { TemplateType } from '../../../consts/types/templates';
import { SceneTemplatesContext } from '../../../providers/sceneTemplates/SceneTemplatesProvider';
import { FcCategoryManagement } from '../../UI/CategoryManagement/CategoryManagement';
import { FcCategoriesWithTemplates } from '../../UI/CategoriesWithTemplates/CategoriesWithTemplates';
import { filterTemplates, filterTemplatesByCategories } from '../../../utils/templateUtils';
import css from './SceneTemplatesRoute.module.scss';

const FcSceneTemplatesRoute = observer(() => {
   const {
      sceneTemplatesList,
      sceneTemplatesByCategories,
      sceneTemplateCategories,
      uncategorizedSceneTemplates,
      addSceneTemplateCategory,
      updateSceneTemplateCategory,
      deleteSceneTemplateCategory,
      addSceneTemplateToCategory,
      deleteSceneTemplateFromCategory,
      moveTemplateToCategory,
      reorderTemplatesInCategory,
   } = useContext(SceneTemplatesContext);

   const searchRef = useRef<HTMLInputElement>(null);
   const [searchFilter, setSearchFilter] = useState('');

   const handleSearchChange = () => {
      if (searchRef.current) {
         setSearchFilter(searchRef.current.value.toLowerCase());
      }
   };

   const filteredTemplatesByCategories = useMemo(() => {
      return filterTemplatesByCategories(sceneTemplatesByCategories, searchFilter);
   }, [sceneTemplatesByCategories, searchFilter]);

   const filteredUncategorizedTemplates = useMemo(() => {
      return filterTemplates(uncategorizedSceneTemplates, searchFilter);
   }, [uncategorizedSceneTemplates, searchFilter]);

   const filteredAllTemplates = useMemo(() => {
      return filterTemplates(sceneTemplatesList, searchFilter);
   }, [sceneTemplatesList, searchFilter]);

   return (
      <div className={css.templatesContainer}>
         <div className={css.header}>
            <h1 className={css.title}>Scene Templates</h1>
            <input
               ref={searchRef}
               type="text"
               placeholder="Search"
               className={css.search}
               onChange={handleSearchChange}
            />
         </div>

         <FcCategoryManagement
            type="sceneTemplate"
            categories={sceneTemplateCategories}
            addCategory={addSceneTemplateCategory}
            updateCategory={updateSceneTemplateCategory}
            deleteCategory={deleteSceneTemplateCategory}
         />

         <FcCategoriesWithTemplates
            templatesByCategories={filteredTemplatesByCategories}
            uncategorizedTemplates={filteredUncategorizedTemplates}
            allTemplates={filteredAllTemplates}
            templateType={TemplateType.SCENE}
            addTemplateToCategory={addSceneTemplateToCategory}
            deleteTemplateFromCategory={deleteSceneTemplateFromCategory}
            moveTemplateToCategory={moveTemplateToCategory}
            reorderTemplatesInCategory={reorderTemplatesInCategory}
         />
      </div>
   );
});

export { FcSceneTemplatesRoute };
