import { Environment } from '../enviroment';

export const env: Environment = {
   name: 'CI',
   firebase: {
      apiKey: 'AIzaSyDoVXElx0moYEmpGflyKjwXDV3U2BevnYE',
      authDomain: 'cms-dev.gloww.com',
      projectId: 'spotlight-dev-us-c64eb',
      storageBucket: 'spotlight-dev-us-c64eb.appspot.com',
      messagingSenderId: '960198601059',
      appId: '1:960198601059:web:c855719472b4292a788b56',
      measurementId: 'G-FT1ZKJP4M5',
      functionsDomain: 'us-central1-spotlight-dev-us-c64eb.cloudfunctions.net',
      functionsRegion: 'us-central1',
      assetsBucket: 'spotlight-dev-us-c64eb-assets-storage',
   },
   appDomain: 'app-dev.gloww.com',
};
