import React, { FC, memo, useContext } from 'react';
import {
   Autocomplete,
   Button,
   Chip,
   Grid,
   Input,
   Switch,
   TextareaAutosize,
   TextField,
} from '@mui/material';
import { FormikProps } from 'formik';
import env from '../../../../../../config/env';
import { SceneTemplate } from '../../../../../../consts/types/templates';
import { SceneTemplatesContext } from '../../../../../../providers/sceneTemplates/SceneTemplatesProvider';
import { FcTierRestrictionsSelection } from '../../../../common/TierRestrictionSelection/TierRestrictionSelection';
import css from './SceneTemplateFormFields.module.scss';

interface Props {
   formik: FormikProps<SceneTemplate>;
}

const FcSceneTemplateFormFields: FC<Props> = (props) => {
   const { formik } = props;
   const { createdAt, id, sessionId } = formik.initialValues;

   const { tags, updateSceneTemplateContent } = useContext(SceneTemplatesContext);

   const onUpdate = async () => {
      try {
         await updateSceneTemplateContent(formik.values.id);
         alert('Updated scene template content successfully!');
      } catch (err) {
         alert('Failed to update scene template content');
      }
   };

   return (
      <div className={css.inputsContainer}>
         <Grid container alignItems="center" gap={2}>
            <label>Name:</label>
            <Input
               className={css.input}
               id="name"
               required
               onChange={formik.handleChange}
               value={formik.values.name}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Image URL:</label>
            <Input
               className={css.input}
               id="previewUrl"
               onChange={formik.handleChange}
               value={formik.values.previewUrl}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Video URL:</label>
            <Input
               className={css.input}
               id="videoUrl"
               onChange={formik.handleChange}
               value={formik.values.videoUrl}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Visible:</label>
            <Switch
               itemID="isVisible"
               checked={formik.values.isVisible}
               onChange={(_, value) => formik.setFieldValue('isVisible', value)}
            />
         </Grid>

         <Grid container gap={2}>
            <label>Description:</label>
            <TextareaAutosize
               id="description"
               minRows={4}
               maxRows={4}
               className={css.textarea}
               value={formik.values.description}
               onChange={formik.handleChange}
            />
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Tags</label>
            <Autocomplete
               multiple
               id="tags-filled"
               value={formik.values?.tags}
               options={tags}
               freeSolo
               limitTags={2}
               onChange={(_, value) => {
                  formik.setFieldValue('tags', [...value]);
               }}
               renderTags={(value: string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                     // eslint-disable-next-line react/jsx-key
                     <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                  ))
               }
               renderInput={(params) => <TextField {...params} placeholder="Start typing..." />}
               sx={{ flex: '1 1 0%' }}
            />
         </Grid>

         <FcTierRestrictionsSelection
            tierRestriction={formik.values.tierRestriction}
            onChange={(tierRestriction) => formik.setFieldValue('tierRestriction', tierRestriction)}
         />

         <Grid container alignItems="center" gap={2}>
            <label>ID:</label>
            <p className={css.input}>{id}</p>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Session ID:</label>
            <p className={css.input}>{sessionId}</p>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Link:</label>
            <div className={css.input}>
               <a
                  href={`https://${env.appDomain}/editor/${sessionId}`}
                  target="_blank"
                  rel="noreferrer"
               >
                  {`${env.appDomain}/editor/${sessionId}`}
               </a>

               <Button
                  color="secondary"
                  variant="outlined"
                  size={'small'}
                  className={css.btn}
                  onClick={onUpdate}
               >
                  Update
               </Button>
            </div>
         </Grid>

         <Grid container alignItems="center" gap={2}>
            <label>Created At:</label>
            <p className={css.input}>{new Date(createdAt).toUTCString()}</p>
         </Grid>
      </div>
   );
};

export default memo(FcSceneTemplateFormFields);
