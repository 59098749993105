import { Template } from '../consts/types/templates';
import { TemplatesByCategories } from '../consts/types/categories';

const doesTemplateMatchFilter = (template: Template, filter: string): boolean => {
   if (
      template.name.toLowerCase().includes(filter) ||
      template.description.toLowerCase().includes(filter)
   ) {
      return true;
   }

   for (const tag in template.tags) {
      if (tag.toLowerCase().includes(filter)) return true;
   }

   return false;
};

const filterTemplatesByCategories = (
   sceneTemplatesByCategories: TemplatesByCategories,
   searchFilter: string
): TemplatesByCategories => {
   const result: TemplatesByCategories = [];
   sceneTemplatesByCategories.forEach((item) => {
      const filteredTemplates = item.templates.filter((template) =>
         doesTemplateMatchFilter(template, searchFilter)
      );
      result.push({ category: item.category, templates: filteredTemplates });
   });
   return result;
};

const filterTemplates = (templates: Template[], searchFilter: string) => {
   return templates.filter((template) => doesTemplateMatchFilter(template, searchFilter));
};

export { doesTemplateMatchFilter, filterTemplatesByCategories, filterTemplates };
