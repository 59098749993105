import { Session } from './sessions';
import { TierRestriction } from './tierRestrictions';

export enum TemplateType {
   SESSION = 'session',
   SCENE = 'scene',
}

export interface TemplateTags {
   tags: string[];
}

export interface TemplateBadge {
   id: string;
   name: string;
   color: string;
}

export interface TemplateBadges {
   badges: TemplateBadge[];
}

export type FbSessionTemplate = {
   name: string;
   description?: string;
   imgURL?: string;
   videoURL?: string;
   sessionId: string;
   session?: Session;
   createdAt: number;
   updatedAt?: number;
   isVisible?: boolean;
   isThemeable?: boolean;
   isTemplateInPool?: boolean;
   supportedThemes?: TemplateSupportedTheme[];
   tags?: string[];
   badgeId?: string;
   audienceSize?: AudienceSize;
   relatedTemplatesIds?: string[];
   tierRestriction?: TierRestriction;
};

export interface SessionTemplate {
   id: string;
   name: string;
   imgURL: string;
   videoURL: string;
   sessionId: string;
   session?: Session;
   createdAt: number;
   updatedAt?: number;
   description: string;
   isVisible: boolean;
   isThemeable?: boolean;
   isTemplateInPool?: boolean;
   supportedThemes?: TemplateSupportedTheme[];
   tags?: string[];
   badgeId?: string;
   audienceSize?: AudienceSize;
   relatedTemplatesIds?: string[];
   tierRestriction?: TierRestriction;
}

export type FbSceneTemplate = {
   name?: string;
   description?: string;
   sessionId: string;
   scenesIds?: string[];
   tags?: string[];
   previewUrl?: string;
   videoUrl?: string;
   isVisible: boolean;
   tierRestriction?: TierRestriction;
   createdAt: number;
   updatedBy: string;
   updatedAt: number;
};

export interface SceneTemplate {
   id: string;
   name: string;
   description: string;
   sessionId: string;
   scenesIds: string[];
   tags: string[];
   previewUrl: string;
   videoUrl: string;
   isVisible: boolean;
   tierRestriction?: TierRestriction;
   createdAt: number;
   updatedBy: string;
   updatedAt: number;
}

export type TemplateSupportedTheme = {
   themeId: string;
   videoPreviewUrl: string;
};

export type AudienceSize = { from: number; to: number };

export type FbSystemThemes = {
   data: any;
   name: string;
   preview: string;
   previewMobile?: string;
};

export type Template = SessionTemplate | SceneTemplate;
