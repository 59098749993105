import React, { FC, SyntheticEvent, useState } from 'react';
import noop from 'lodash/noop';
import clsx from 'clsx';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SessionTemplate, SceneTemplate, Template } from '../../../consts/types/templates';
import { FcPremiumIndication } from '../PremiumIndication/PremiumIndication';
import css from './TemplatePreview.module.scss';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
   template: Template;
   onClick?: () => void;
   rootCss?: string;
   isSelected?: boolean;
   isSelectable?: boolean;
}

const getPreviewUrl = (template: Template): string => {
   if ('imgURL' in template) {
      return (template as SessionTemplate).imgURL;
   }
   if ('previewUrl' in template) {
      return (template as SceneTemplate).previewUrl;
   }
   return '';
};

const FcTemplatePreview: FC<Props> = (props) => {
   const { template, onClick = noop, isSelected, rootCss, isSelectable = false } = props;
   const [selected, setSelected] = useState(isSelected);

   const isPremium = !!template.tierRestriction && template.tierRestriction !== 'Free';
   const handleClick = (e: SyntheticEvent) => {
      e.preventDefault();
      onClick();
      isSelectable && setSelected((prev) => !prev);
   };

   return (
      <div
         className={clsx(css.templateItem, selected && css.selected, rootCss)}
         onClick={handleClick}
      >
         <img
            src={getPreviewUrl(template)}
            className={css.img}
            onError={({ currentTarget }) => {
               currentTarget.onerror = null; // prevents looping
               currentTarget.src = '/blank.svg';
            }}
            alt={template.name}
         />
         <span className={css.name}>{template.name}</span>
         {!template.isVisible && <p className={css.hidden}>HIDDEN</p>}
         {isSelectable && (
            <span className={css.icon}>{selected ? <DeleteOutlineIcon /> : <AddIcon />}</span>
         )}
         {isPremium && <FcPremiumIndication />}
      </div>
   );
};

export { FcTemplatePreview };
